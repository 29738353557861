import {
  Box, Container, Flex, Heading
} from "@chakra-ui/react";
import React from 'react';
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import MaskSlidInAnimation from "../DefinitionComponents/Animation-Components/MaskSlideInAnimation";
import InViewTrigger from "../DefinitionComponents/Utility/UX/InViewTrigger";

function  IntroComponent2colData({headline,text1,text2,text3,text4, variant}) {

  return (
    <Box w="100%">

    <Container
      variant="layoutContainer"
      px={{base:'0',lg:'10'}}
      py={20}
      bg="brand.lightgray"
    >
      <Box w={{ lg: "100%", base: "100%" }}>
        <InViewTrigger threshold={0.4} triggerOnce={false}>
          {(inView) => (
            <Flex
            flexWrap="wrap"
              direction={{ base: "column", md: "row" }}
              spacing={4}
              align="stretch"
            >
              <Box minW={"100%" } >
                <MaskSlidInAnimation threshold={1} duration={0.6}>
                  <Heading
                    fontSize="5xl"
                    fontWeight="normal"
                    mb="4"
                    letterSpacing="tighter"
                    color="brand.blue"
                  >
              
                   {headline}
                  </Heading>
                </MaskSlidInAnimation>
              </Box>
              <Flex  minW={"100%" } >
              <Box w="50%">
                <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
                  <Heading
                    fontSize="xl"
                    fontWeight="normal"
                    mb="4"
                    color="brand.meddarkgray"
                  >
                      {text1}
                  </Heading>
                </FadeInAnimation>
                <FadeInAnimation threshold={0.4} duration={1} delay={0.8}>
                  <Heading
                    fontSize="md"
                    fontWeight="normal"
                    color="brand.meddarkgray"
                    dangerouslySetInnerHTML={{__html: text2}}
                  >
                    
                  </Heading>
                </FadeInAnimation>
              </Box>
              <Box w="50%">
                <FadeInAnimation threshold={0.4} duration={1} delay={0.4}>
                  <Heading
                    fontSize="xl"
                    fontWeight="normal"
                    mb="4"
                    color="brand.meddarkgray"
                  >
                     {text3}
                  </Heading>
                </FadeInAnimation>
                <FadeInAnimation threshold={0.4} duration={1} delay={0.8}>
                  <Heading
                    fontSize="md"
                    fontWeight="normal"
                    color="brand.meddarkgray"  dangerouslySetInnerHTML={{__html: text4}}
                  >
                   
                  </Heading>
                </FadeInAnimation>
              </Box>
              </Flex>
            </Flex>
          )}
        </InViewTrigger>
      </Box>
    </Container>
  </Box>
  )
}

export default IntroComponent2colData